<template>
  <div class="chart-summary">
    <div class="chart-header">
      <span class="chart-date">11月20日</span>
      <span class="chart-header-item">睡眠总时长：</span>
      <span class="chart-header-value">43</span>
      <span class="chart-unit">h</span>
      <span class="chart-header-item">运动总步数：</span>
      <span class="chart-header-value">5999</span>
      <span class="chart-unit">步</span>
    </div>
    <div class="chart-chart" ref="lineRef"></div>
    <div class="chart-items">
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--purple">深睡眠</div>
        <div class="chart-item__body">
          <span class="chart-value">423</span>
          <span class="chart-unit">h</span>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--purple">浅睡眠</div>
        <div class="chart-item__body">
          <span class="chart-value"></span>
          <span class="chart-unit">h</span>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--purple">快速眼动</div>
        <div class="chart-item__body">
          <span class="chart-value"></span>
          <span class="chart-unit">h</span>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--purple chart-item__title--purple">清醒</div>
        <div class="chart-item__body">
          <span class="chart-value"></span>
          <span class="chart-unit">h</span>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--orange">总能耗</div>
        <div class="chart-item__body">
          <span class="chart-value"></span>
          <span class="chart-unit">kCal</span>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--orange">有效能耗</div>
        <div class="chart-item__body">
          <span class="chart-value"></span>
          <span class="chart-unit">kCal</span>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--orange">有效时间</div>
        <div class="chart-item__body">
          <span class="chart-value"></span>
          <span class="chart-unit">min</span>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--pink">平均心率</div>
        <div class="chart-item__body">
          <span class="chart-value"></span>
          <span class="chart-unit">次/分</span>
        </div>
      </div>
      <div class="chart-item">
        <div class="chart-item__title chart-item__title--green">平均血氧</div>
        <div class="chart-item__body">
          <span class="chart-value"></span>
          <span class="chart-unit">%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { init as initChart } from 'echarts';

export default {
	props: {
		data: {},
	},
	data() {
		return {};
	},
	methods: {
		genChart() {
			initChart(this.$refs.lineRef).setOption({
				legend: {
					bottom: 20,
					right: 60,
					data: [
						{ name: '深睡' },
						{ name: '浅睡' },
						{ name: '快速眼动' },
						{ name: '清醒' },
						{ name: '心率' },
						{ name: '运动' },
					],
				},
				grid: {
					top: 20,
					left: 60,
					right: 60,
					bottom: 80,
					show: true,
					borderColor: '#e2eef8',
					backgroundColor: '#e2eef8',
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: { type: 'shadow' },
				},
				xAxis: {
					type: 'category',
					data: ['12:00', '14:00', '16:00', '18:00', '20:00', '22:00'],
					axisLine: { lineStyle: { color: '#999' } },
					axisTick: { show: false },
				},
				yAxis: {
					type: 'value',
					splitLine: { show: false },
					axisLine: { show: false },
					axisTick: { show: false },
				},
				barGap: 0,
				series: [
					{
						name: '深睡',
						type: 'bar',
						color: '#904ed8',
						data: [18203, 23489, 29034, 10470, 11744, 30230],
					},
					{
						name: '浅睡',
						type: 'bar',
						color: '#12a7ef',
						data: [19325, 23438, 31000, 11594, 14141, 81807],
					},
					{
						name: '快速眼动',
						type: 'bar',
						color: '#fabf02',
						data: [18203, 23489, 29034, 14970, 11744, 60230],
					},
					{
						name: '清醒',
						type: 'bar',
						color: '#ff7601',
						data: [19325, 23438, 31000, 12194, 14141, 81807],
					},
					{
						name: '心率',
						type: 'line',
						color: '#f4505f',
						smooth: true,
						data: [21820, 12932, 19014, 22934, 11290, 21330, 31320],
						areaStyle: { opacity: 0.2 },
					},
					{
						name: '运动',
						type: 'line',
						color: '#43c4b4',
						smooth: true,
						data: [1820, 2932, 3901, 2934, 1290, 5330, 4320],
						symbol: 'none',
					},
				],
			});
		},
	},
	mounted() {
		this.genChart();
	},
};
</script>

<style lang="less">
.chart-summary {
  background-color: #fff;
  padding-bottom: 20px;

  .chart-header {
    height: 40px;
    line-height: 40px;
    background-color: #e9ebec;
    padding: 0 60px;
  }

  .chart-date {
    font-size: 20px;
  }

  .chart-header-value {
    font-size: 20px;
    font-weight: 700;
    color: #f28b31;
  }

  .chart-header-item {
    margin-left: 150px;
  }

  .chart-chart {
    height: 300px;
    width: 100%;
  }

  .chart-items {
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 0 60px;
  }

  .chart-item {
    border-radius: 8px;
    border: 1px solid #eee;
    text-align: center;
    width: calc(100% / 9);

    &__title {
      color: #fff;
      height: 32px;
      line-height: 32px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      &--purple {
        background-color: #a771df;
      }

      &--orange {
        background-color: #f28b31;
      }

      &--pink {
        background-color: #f7737f;
      }

      &--green {
        background-color: #34bfae;
      }
    }

    &__body {
      height: 64px;
      line-height: 64px;
    }
  }

  .chart-value {
    font-size: 28px;
    font-weight: 700;
  }

  .chart-unit {
    color: #999;
    margin-left: 5px;
  }
}
</style>
