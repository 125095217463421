<template>
  <div class="watch-line">
    <chart-summary></chart-summary>
    <chart-summary></chart-summary>
    <chart-summary></chart-summary>
    <chart-summary></chart-summary>
    <chart-summary></chart-summary>
    <chart-summary></chart-summary>
    <chart-summary></chart-summary>
  </div>
</template>

<script>
import ChartSummary from './chart-summary';

export default {
	components: { ChartSummary },
};
</script>
